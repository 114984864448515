import React, { useEffect, useRef, useState } from "react";
import _ from "lodash";
import QueryBar from "../Common/QueryBar";
import Loader from "./Loader";

const BOTS = {
  "elon_musk": "Elon Musk",
  "indra": "Indra Nooyi",
  "newton": "Isaac Newton",
  "serena": "Serena Williams",
  "modi": "Narendra Modi"
}
function SimpleCard({ setIsSpeaking, isSpeaking, botId }) {
  const [words, setWords] = useState([]);
  const containerRef = useRef(null);
  const [response, setResponse] = useState("");
  const [generatingResponse, setGeneratingResponse] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [chatData, setChatData] = useState([
    {
      type: "ai",
      content: `Hello, I am ${BOTS[botId]} and I am here to help you with your queries.`,
      format: "text",
    },
  ]);

  
  useEffect(() => {
    scrollToBottom();
  }, [chatData, isLoading]);

  // is loading
  useEffect(() => {
    setIsLoading(generatingResponse);
  }, [generatingResponse]);

  useEffect(() => {
    // Split the sentence into words
    if (response) {
      setChatData((prevData) => {
        return [...prevData, { type: "ai", content: response, format: "text" }];
      });
      // const wordsArray = response.split(" ");
      // let isCancelled = false;

      // // Function to update words with a delay
      // const updateWordsWithDelay = async () => {
      //   for (let i = 0; i < wordsArray.length; i++) {
      //     if (isCancelled) break;
      //     await new Promise((resolve) => setTimeout(resolve, 200));
      //     setWords((prevWords) => [...prevWords, wordsArray[i]]);
      //   }
      // };

      // // Call the function to start animation
      // updateWordsWithDelay();

      // // Clean up function
      // return () => {
      //   isCancelled = true;
      //   setWords([]);
      // };
    }
  }, [response]);

  useEffect(() => {
    // Scroll to the bottom whenever content changes
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [words]);

  
  const getUserInput = (type, data) => {
    setChatData((prevData) => {
      return [...prevData, { type: "user", content: data, format: type }];
    });
  };

  const scrollToBottom = () => {
    if (containerRef.current) {
      const { scrollHeight, clientHeight } = containerRef.current;
      containerRef.current.scrollTop = scrollHeight - clientHeight;
    }
  };

  return (
    <div className="container shadow-md bg-white w-[60vw] h-[30rem] xl:h-[29rem] rounded-md">
      <div className="">
        {/* <h1 className="text-center shadow-lg text-base ai-response bg-white">
          Ai Response
        </h1> */}
        <p
          ref={containerRef}
          className="overflow-y-auto h-[23rem] overflow-x-hidden px-6 pt-2"
        >
          {/* {!_.isEmpty(response) &&
            words.map((word, index) => <span key={index}>{word} </span>)} */}
          {chatData.map((item, index) => {
            if (item.type === "ai") {
              return (
                <p className="speech-bubble speech-bubble-left" key={index}>
                  {item.content}{" "}
                </p>
              );
            } else if (item.type === "user") {
              if (item.format === "text") {
                return (
                  <p
                    className="speech-bubble speech-bubble-right speech-bubble-blue"
                    key={index}
                  >
                    {item.content}{" "}
                  </p>
                );
              } else {
                return (
                  <p
                    className="speech-bubble speech-bubble-right speech-bubble-blue"
                    key={index}
                  >
                    <audio className="" controls>
                      <source src={item.content} type="audio/mp3" />
                      Your browser does not support the audio tag.
                    </audio>
                  </p>
                );
              }
            }
          })}
          {isLoading && (
            <p className="speech-bubble speech-bubble-left">
              <Loader />
            </p>
          )}
        </p>
      </div>
      <QueryBar
        setResponse={setResponse}
        isSpeaking={isSpeaking}
        setIsSpeaking={setIsSpeaking}
        getUserInput={getUserInput}
        setGeneratingResponse={setGeneratingResponse}
      />
    </div>
  );
}

export default SimpleCard;
