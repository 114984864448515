import axios from "axios";
import { model_url } from "./services";
// require('dotenv').config();

const API_URL = model_url;
// const API_URL = "https://stagingapi.affiliate.datatobiz.com"

const Axios = axios.create({
  baseURL: API_URL, // Replace with your backend API URL
});

// Function to set the token in the request headers
const setAuthToken = (token) => {
  if (token) {
    // Apply the token to every request header
    Axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    localStorage.setItem("loggedUserToken", token); // Store the token in local storage
  } else {
    // Remove the token from the request headers
    delete Axios.defaults.headers.common["Authorization"];
    localStorage.removeItem("loggedUserToken"); // Remove the token from local storage
  }
};

export { Axios, setAuthToken };